import React, { createRef, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './source.scss';

export const UploadSourceScreen: React.FC = () => {
  const navigate = useNavigate();

  const videoInput = createRef<HTMLInputElement>();

  const onDeviceUploadClick = useCallback(() => {
    videoInput.current?.click();
  }, [videoInput]);

  const onDeviceUploadChange = useCallback(() => {
    if (!videoInput.current) return;
    if (!videoInput.current.files) return;
    if (videoInput.current.files.length < 1) return;
    const videoFile = videoInput.current.files.item(0)!;
    const videoBlobUrl = URL.createObjectURL(videoFile);
    navigate('/upload/info', { state: { videoBlobUrl } });
  }, [navigate, videoInput]);

  return (
    <div className={'screen screen-upload-source'}>
      <h1>Upload From...</h1>

      <input
        type={'file'}
        accept={'video/*'}
        className={'screen-upload-source-input'}
        ref={videoInput}
        onChange={onDeviceUploadChange}
      />

      <div className={'screen-upload-source-wrapper'}>
        <div className={'screen-upload-source-source'}>
          <button type={'button'} className={'btn btn-primary'} onClick={onDeviceUploadClick}>Device</button>
          <label className={'screen-upload-source-label'}>Upload a video you&apos;ve already recorded from your device.</label>
        </div>
        <hr />
        <div className={'screen-upload-source-source'}>
          <Link to={'/record'} className={'btn btn-primary'}>Camera</Link>
          <label className={'screen-upload-source-label'}>Record a video now and upload it.</label>
        </div>
      </div>
    </div>
  );
};
