import React, {
  createRef, useCallback, useEffect, useReducer, useRef,
} from 'react';
import cx from 'classnames';
import { animated, useSpring } from '@react-spring/web';
import './record.scss';
import { useNavigate } from 'react-router-dom';

interface RecordButtonProps {
  isPressed: boolean;
  onPress: () => void;
  onRelease: () => void;
}

const RecordButton: React.FC<RecordButtonProps> = ({ isPressed, onPress, onRelease }) => {
  const inner = useSpring({
    to: {
      r: isPressed ? 35 : 0,
    },
  });

  const outer = useSpring({
    to: {
      r: isPressed ? 45 : 35,
      strokeWidth: isPressed ? 5 : 7,
    },
  });

  return (
    <svg
      className={'screen-record-btn-record'}
      viewBox={'0 0 100 100'}
      onPointerDown={onPress}
      onPointerUp={onRelease}
    >
      <animated.circle
        cx={50} cy={50}
        stroke={'white'} fill={'transparent'}
        style={outer}
      />

      <animated.circle
        cx={50} cy={50}
        fill={'red'}
        style={inner}
      />
    </svg>
  );
};

export interface Recording {
  /** The length of the recording in milliseconds. */
  length: number;

  /** The video data that makes up the recording. */
  data: Blob;
}

const formatDuration = (millis: number) =>
  `${(Math.floor(millis / 60_000) % 60).toString().padStart(2, '0')}${':'}${(Math.floor(millis / 1000) % 60).toString().padStart(2, '0')}`;

const RECORDING_LIMIT = 90_000;

enum RecordingMode {
  Init,
  Ready,
  Recording,
  Paused,
  Error,
}

type RecordingState = {
  mode: RecordingMode.Init,
} | {
  mode: RecordingMode.Ready,
  stream: MediaStream,
} | {
  mode: RecordingMode.Recording,
  stream: MediaStream,
  start: number,
  duration: number,
  data: Blob[],
} | {
  mode: RecordingMode.Paused,
  stream: MediaStream,
  duration: number,
  data: Blob[],
} | {
  mode: RecordingMode.Error,
  error: Error,
};

enum RecordingActionType {
  Init,
  Resume,
  Pause,
  Error,
  NewData,
  Reset,
  Finish,
}

type RecordingAction =
 | {
   type: RecordingActionType.Init,
   stream: MediaStream,
 }
 | { type: RecordingActionType.NewData, data: Blob }
 | { type: RecordingActionType.Reset }
 | { type: RecordingActionType.Resume }
 | { type: RecordingActionType.Pause }
 | { type: RecordingActionType.Finish }
 | { type: RecordingActionType.Error, error: Error };

export const RecordingScreen: React.FC = () => {
  const navigate = useNavigate();

  const [state, dispatch] = useReducer((state: RecordingState, action: RecordingAction): RecordingState => {
    console.log(action);
    switch (action.type) {
    case RecordingActionType.Init: {
      if (state.mode === RecordingMode.Init) {
        return {
          mode: RecordingMode.Ready,
          stream: action.stream,
        };
      }
      break;
    }

    case RecordingActionType.Resume: {
      if (state.mode === RecordingMode.Paused) {
        return {
          mode: RecordingMode.Recording,
          stream: state.stream,
          duration: state.duration,
          data: state.data,
          start: Date.now(),
        };
      }

      if (state.mode === RecordingMode.Ready) {
        return {
          mode: RecordingMode.Recording,
          stream: state.stream,
          duration: 0,
          data: [],
          start: Date.now(),
        };
      }
      break;
    }

    case RecordingActionType.Pause: {
      if (state.mode === RecordingMode.Recording) {
        return {
          mode: RecordingMode.Paused,
          stream: state.stream,
          duration: Date.now() - state.start + state.duration,
          data: state.data,
        };
      }
      break;
    }

    case RecordingActionType.NewData: {
      if (state.mode === RecordingMode.Recording || state.mode === RecordingMode.Paused) {
        return {
          ...state,
          data: [...state.data, action.data],
        };
      }
      break;
    }

    case RecordingActionType.Reset: {
      if (state.mode === RecordingMode.Ready
        || state.mode === RecordingMode.Recording
        || state.mode === RecordingMode.Paused)
        return {
          mode: RecordingMode.Ready,
          stream: state.stream,
        };
      break;
    }

    case RecordingActionType.Error: {
      return {
        mode: RecordingMode.Error,
        error: action.error,
      };
    }

    case RecordingActionType.Finish: {
      if (state.mode === RecordingMode.Paused) {
        const videoBlob = new Blob(state.data, { type: 'video/mp4' });
        const videoBlobUrl = URL.createObjectURL(videoBlob);
        console.log('created video blob url', videoBlobUrl);
        navigate('/upload/info', { state: { videoBlobUrl } });
      }
    }
    }

    return state;
  }, { mode: RecordingMode.Init });

  const isActive = (state.mode === RecordingMode.Recording || state.mode === RecordingMode.Paused);
  const isReady = (state.mode === RecordingMode.Ready || isActive);

  const videoRef = createRef<HTMLVideoElement>();
  const clockRef = createRef<HTMLDivElement>();
  const rootRef = createRef<HTMLDivElement>();
  const timerRef = useRef<number>();
  const recorderRef = useRef<MediaRecorder>();

  const dispatchError = (error: Error) => {
    console.error(error);
    dispatch({
      type: RecordingActionType.Error,
      error,
    });
  };

  useEffect(() => {
    const promise = navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: {
          facingMode: 'environment',
          width: 1920,
          height: 1080,
          // aspectRatio: 9 / 16,
          frameRate: 60,
          zoom: true,
        },
      });

    promise
      .then((stream) => {
        dispatch({
          type: RecordingActionType.Init,
          stream,
        });
      })
      .catch(dispatchError);

    return () => {
      promise
        .then((mediaStream) => {
          recorderRef.current?.stop();

          for (const track of mediaStream.getTracks()) {
            track.stop();
            // mediaStream.removeTrack(track);
          }
        })
        .catch(dispatchError);
    };
  }, []);

  useEffect(() => {
    if (isReady && !recorderRef.current) {
      console.log('creating new media recorder');

      let recorder: MediaRecorder;
      const bitsPerSecond = 12e6;

      // use MP4 if possible, fall back to default otherwise
      try {
        recorder = new MediaRecorder(state.stream, {
          mimeType: 'video/mp4',
          bitsPerSecond,
        });
      } catch {
        console.warn('could not initialize mp4 recorder, falling back');
        recorder = new MediaRecorder(state.stream, { bitsPerSecond });
      }

      recorder.ondataavailable = event => {
        console.debug('recorder data');
        console.log('recorder state', recorder.state);
        dispatch({
          type: RecordingActionType.NewData,
          data: event.data,
        });
      };

      recorder.onpause = () => {
        console.debug('recorder paused');
        console.log('recorder state', recorder.state);
        dispatch({ type: RecordingActionType.Pause });
        recorder.requestData();
      };

      recorder.onresume = () => {
        console.debug('recorder resumed');
        console.log('recorder state', recorder.state);
        dispatch({ type: RecordingActionType.Resume });
      };

      recorder.onstart = () => {
        console.debug('recorder started');
        console.log('recorder state', recorder.state);
        dispatch({ type: RecordingActionType.Resume });
      };

      recorder.onstop = () => console.debug('recorder stopped');

      recorder.onerror = event => dispatchError(event.error);

      recorderRef.current = recorder;

      if (videoRef.current) {
        const videoEl = videoRef.current;
        videoEl.srcObject = state.stream;
        videoEl.play().catch(dispatchError);
      }
    }

  // don't need to include state.stream b/c its presence is captured in isReady
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, videoRef, recorderRef]);

  useEffect(() => {
    if (state.mode === RecordingMode.Recording) {
      timerRef.current = setInterval(() => {
        if (clockRef.current) {
          clockRef.current.innerText =
            `${formatDuration(Date.now() - state.start + state.duration)} / ${formatDuration(RECORDING_LIMIT)}`;
        }
      }, 100);
    } else {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    }

  // don't need to include state.start or state.duration b/c they cannot change without
  // state.mode also changing
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clockRef, state.mode]);

  const onRecordPress = useCallback(() => {
    if (isActive) {
      recorderRef.current?.resume();
      console.log('recorder state', recorderRef.current?.state);
    } else {
      recorderRef.current?.start(1000);
      console.log('recorder state', recorderRef.current?.state);
    }
  }, [isActive, recorderRef]);

  const onRecordRelease = useCallback(() => {
    if (isActive) {
      recorderRef.current?.pause();
    }
  }, [isActive, recorderRef]);

  return (
    <div className={'screen-record'} ref={rootRef}>
      <video
        className={'screen-record-viewfinder'}
        ref={videoRef}
        autoPlay={true}
        muted={true}
        playsInline={true}
      />

      <div className={'screen-record-hud'}>
        {isActive && (
          <div
            className={cx(
              'screen-record-clock',
              {
                'screen-record-clock-active': state.mode === RecordingMode.Recording,
              }
            )}
            ref={clockRef}
          />
        )}

        <button
          type={'button'}
          className={'screen-record-btn screen-record-btn-clear'}
          disabled={state.mode !== RecordingMode.Paused}
          onClick={() => {
            recorderRef.current?.stop();
            dispatch({ type: RecordingActionType.Reset });
          }}
        >
          🗑
        </button>

        <button
          type={'button'}
          className={'screen-record-btn screen-record-btn-confirm'}
          disabled={state.mode !== RecordingMode.Paused}
          onClick={() => {
            recorderRef.current?.stop();
            dispatch({ type: RecordingActionType.Finish });
          }}
        >
          ✅
        </button>

        {isReady && (
          <RecordButton
            isPressed={state.mode === RecordingMode.Recording}
            onPress={onRecordPress}
            onRelease={onRecordRelease}
          />
        )}
      </div>
    </div>
  );
};
