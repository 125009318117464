import React, { Component, useEffect } from 'react';
import './app.scss';

import { Provider } from 'react-redux';
import { store, useAppDispatch, useAppSelector } from './store';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { RecordingScreen } from './screens/record';
import { AssignmentScreen } from './screens/assignment';
import { createUpdateStatus } from './store/game-status';
import { VerifyScreen } from './screens/verify';
import { AdminScreen } from './screens/admin';
import { TeamsScreen } from './screens/teams';
import { UploadSourceScreen } from './screens/upload/source';
import { UploadInfoScreen } from './screens/upload/info';
import { TimelineScreen } from './screens/timeline';

// Inner component needed so that we can use useAppSelector and useAppDispatch
// which depend on the Provider in App
const AppInner: React.FC = () => {
  const gameStatus = useAppSelector(state => state.gameStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (gameStatus.self === null) {
      window.location.pathname = '/auth/login';
    }

    if (gameStatus.self === undefined) {
      dispatch(createUpdateStatus());
    }
  }, [dispatch, gameStatus.self]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<AssignmentScreen />} />
        <Route path={'/record'} element={<RecordingScreen />} />
        <Route path={'/upload/info'} element={<UploadInfoScreen />} />
        <Route path={'/upload/source'} element={<UploadSourceScreen />} />
        <Route path={'/verify'} element={<VerifyScreen />} />
        <Route path={'/timeline'} element={<TimelineScreen />} />
        <Route path={'/admin'} element={<AdminScreen />} />
        <Route path={'/teams'} element={<TeamsScreen />} />
      </Routes>
    </BrowserRouter>
  );
};

const App: React.FC = () => {

  return (
    <div className={'app'}>
      <Provider store={store}>
        <AppInner />
      </Provider>
    </div>
  );
};

export default App;
