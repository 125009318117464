import React, { createRef, useEffect, useRef } from 'react';
import { PlayerSdk } from '@api.video/player-sdk';

export interface ApiVideoPlayerProps {
  className?: string;

  videoId: string;

  muted?: boolean;
  loop?: boolean;
  autoplay?: boolean;
  chromeless?: boolean;
  hideControls?: boolean;
  hideTitle?: boolean;
  volume?: number;
}

export const ApiVideoPlayer: React.FC<ApiVideoPlayerProps> = ({
  className,
  videoId,
  muted,
  loop,
  autoplay,
  volume,
  chromeless,
  hideControls,
  hideTitle,
}) => {
  const container = createRef<HTMLDivElement>();
  const player = useRef<PlayerSdk>();

  useEffect(() => {
    if (!container.current) return;

    player.current = new PlayerSdk(container.current, {
      id: videoId,
      hideTitle,
      hideControls,
      chromeless,
      autoplay,
      muted,
      loop,
    });

    return () => player.current?.destroy();

  // don't include videoId in deps b/c changes are handled in the next useEffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container]);

  useEffect(() => {
    if (!player.current) return;

    player.current.loadConfig({
      id: videoId,
      hideTitle,
      hideControls,
      chromeless,
      autoplay,
      muted,
      loop,
    });

  // don't include other props in deps b/c changes are handled in the next useEffects
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId, hideTitle, hideControls]);

  useEffect(() => player.current?.setAutoplay(autoplay ?? false), [autoplay]);
  useEffect(() => player.current?.setLoop(loop ?? false), [loop]);
  useEffect(() => player.current?.setChromeless(chromeless ?? false), [chromeless]);
  useEffect(() => player.current?.setVolume(muted === false ? 0 : (volume ?? 1)), [volume, muted]);

  return (<div ref={container} className={className}></div>);
};
