import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { useAppSelector } from '../store';
import './teams.scss';
import { formatName, shuffle } from '@/util';

export const TeamsScreen: React.FC = () => {
  const gameStatus = useAppSelector(state => state.gameStatus);

  const teams = useMemo(() => {
    if (!gameStatus.teams) return [];
    
    return shuffle(Object.entries(gameStatus.teams));
  }, [gameStatus.teams]);

  return (
    <div className={'screen screen-teams'}>
      <h1 className={'screen-teams-header'}>Teams</h1>

      <div className={'screen-teams-teams'}>
        {teams.map(([key, team]) => (
          <div className={'screen-teams-team'} key={key}>
            <h2 className={cx('screen-teams-team-name', `screen-teams-team-name-${team.status}`)}>{team.displayName}</h2>
            <ul className={'screen-teams-team-users'}>
              {gameStatus.users && team.users
              // the users from targetTeam.users are partial objects w/ only
              // netId and displayName
                .map(u => gameStatus.users![u.netId])
                .map(u => (
                  <li
                    key={u.netId}
                    className={cx('player-badge', `player-badge-${u.status}`)}
                  >
                    {formatName(u)}
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
