import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { useAppSelector } from '../store';
import './assignment.scss';
import { formatName } from '@/util';

export const AssignmentScreen: React.FC = () => {
  const gameStatus = useAppSelector(state => state.gameStatus);

  const ownTeam = useMemo(() => {
    if (!gameStatus.self || !gameStatus.teams) return null;
    const ownTeamId = gameStatus.self.team as number;
    return gameStatus.teams[ownTeamId];
  }, [gameStatus.self, gameStatus.teams]);

  const targetTeam = useMemo(() => {
    if (!ownTeam || !ownTeam.target) return null;
    return gameStatus.teams![ownTeam.target.id];
  }, [gameStatus.teams, ownTeam]);

  return (
    <div className={'screen screen-assignment'}>
      <span>Welcome,</span>
      <h1>{gameStatus.self && formatName(gameStatus.self)}</h1>

      {
        gameStatus.self?.roles.player && (ownTeam && targetTeam ? (
          <>
            <div className={'screen-assignment-team-info'}>
              <span>You are on team</span>
              <h1>{ownTeam.displayName}</h1>
              <ul className={'screen-assignment-teammates'}>
                {gameStatus.users && ownTeam.users
                  // the users from ownTeam.users are partial objects w/ only
                  // netId and displayName
                  .map(u => gameStatus.users![u.netId])
                  .map(u => (
                    <li
                      key={u.netId}
                      className={cx('player-badge', `player-badge-${u.status}`)}
                    >
                      {formatName(u)}
                    </li>
                  ))}
              </ul>
            </div>

            <hr />

            <div className={'screen-assignment-target-info'}>
              <span>
                You need to eliminate team
              </span>
              <h1>{targetTeam.displayName}</h1>
              <ul className={'screen-assignment-targets'}>
                {gameStatus.users && targetTeam.users
                  // the users from targetTeam.users are partial objects w/ only
                  // netId and displayName
                  .map(u => gameStatus.users![u.netId])
                  .map(u => (
                    <li
                      key={u.netId}
                      className={cx('player-badge', `player-badge-${u.status}`)}
                    >
                      {formatName(gameStatus.users![u.netId])}
                    </li>
                  ))}
              </ul>
            </div>

            <hr />

            <Link className={'btn btn-primary'} to={'/upload/source'}>
              Upload Elimination Video
            </Link>
          </>
        ) : (
          <span>Teams have not been assigned yet. Please come back later.</span>
        ))
      }

      <Link className={'btn btn-secondary'} to={'/teams'}>
        View Teams
      </Link>

      <Link className={'btn btn-secondary'} to={'/timeline'}>
        View Eliminations
      </Link>

      {(gameStatus.self?.roles.verifier || gameStatus.self?.roles.admin) && (
        <hr />
      )}

      {
        gameStatus.self?.roles.verifier && (
          <Link className={'btn btn-secondary'} to={'/verify'}>
            View Unverified Videos
          </Link>
        )
      }

      {
        gameStatus.self?.roles.admin && (
          <Link className={'btn btn-secondary'} to={'/admin'}>
            View Admin Dashboard
          </Link>
        )
      }
    </div>
  );
};
