import React, {
  createRef, useCallback, useEffect, useMemo, useReducer,
} from 'react';

import logoLoading from '../asset/160/loading-red.png';
import './verify.scss';
import { Upload } from '@/store/game-status';
import { formatName } from '@/util';
import { ApiVideoPlayer } from '@/components/apivideo';

interface VerifyState {
  currentUpload: Upload | null | undefined;
}

type VerifyAction =
  | { type: 'accept-elim', id: number; }
  | { type: 'reject-elim', id: number; }
  | { type: 'receive-upload', upload: Upload | null; };

export const VerifyScreen: React.FC = () => {
  const videoRef = createRef<HTMLVideoElement>();

  const [verifyState, dispatch] = useReducer((state: VerifyState, action: VerifyAction): VerifyState => {
    switch (action.type) {
    case 'accept-elim':

    case 'reject-elim': {
      if (!state.currentUpload) return state;

      const selectedElim = state.currentUpload.eliminations.find(e => e.id === action.id);
      const otherElims = state.currentUpload.eliminations.filter(e => e.id !== action.id);
      if (!selectedElim) return state;

      // if there are no other elims to verify, clear this upload so another one
      // will be requested
      if (otherElims.every(e => e.verified !== null)) {
        return {
          ...state,
          currentUpload: undefined,
        };
      }

      return {
        ...state,
        currentUpload: {
          ...state.currentUpload,
          eliminations: [
            ...otherElims,
            {
              ...selectedElim,
              verified: action.type === 'accept-elim',
            },
          ],
        },
      };
    }

    case 'receive-upload': {
      return {
        ...state,
        currentUpload: action.upload,
      };
    }
    }
  }, {
    currentUpload: undefined,
  });

  const firstUnverifiedElimination = useMemo(() => {
    if (!verifyState.currentUpload) return undefined;
    return verifyState.currentUpload.eliminations.find(e => e.verified === null);
  }, [verifyState.currentUpload]);

  useEffect(() => {
    if (verifyState.currentUpload === undefined) {
      (async () => {
        const nextUploadResponse = await fetch('/api/verify/next');
        if (nextUploadResponse.status === 404) {
          dispatch({
            type: 'receive-upload',
            upload: null,
          });
          return;
        }

        if (nextUploadResponse.status !== 200) {
          throw new Error('oops');
        }

        const nextUpload = await nextUploadResponse.json();
        dispatch({
          type: 'receive-upload',
          upload: nextUpload,
        });
      })().catch(console.error);
    }
  }, [verifyState.currentUpload]);

  const onVideoClicked = useCallback(() => {
    if (videoRef.current!.paused) {
      videoRef.current!.play().catch(console.error);
    } else {
      videoRef.current!.pause();
    }
  }, [videoRef]);

  const onJudgementClicked = useCallback((judgement: boolean) => {
    if (!firstUnverifiedElimination) return;

    const id = firstUnverifiedElimination.id;
    (async () => {
      const res = await fetch(`/api/elimination/${id}`, {
        method: 'post',
        body: JSON.stringify({ judgement }),
        headers: {
          'content-type': 'application/json',
        },
      });

      if (res.status !== 200) {
        throw new Error('oops');
      }

      dispatch({
        type: judgement ? 'accept-elim' : 'accept-elim',
        id,
      });
    })().catch(console.error);
  }, [firstUnverifiedElimination]);

  return (
    <div className={'screen-verify'}>
      {verifyState.currentUpload && (
        <>
          <div className={'screen-verify-video-wrapper'}>
            {
              verifyState.currentUpload.video.thirdPartyId
                ? (
                  <ApiVideoPlayer
                    className={'screen-verify-video-external'}
                    videoId={verifyState.currentUpload.video.thirdPartyId}
                    hideTitle={true}
                  />
                )
                : (
                  <video
                    src={`/api/upload/${verifyState.currentUpload.id}/stream`}
                    autoPlay={true}
                    playsInline={true}
                    loop={true}
                    controls={true}
                    className={'screen-verify-video'}
                    ref={videoRef}
                    onClick={onVideoClicked}
                  />
                )
            }

          </div>
          <span className={'screen-verify-prompt'}>
            This video was uploaded at {new Date(verifyState.currentUpload.date).toLocaleString('en-US', {
              timeStyle: 'short',
              dateStyle: 'short',
            })}. <br />
            Did <strong>{firstUnverifiedElimination?.eliminator && formatName(firstUnverifiedElimination.eliminator)}</strong>{' '}
            eliminate <strong>{firstUnverifiedElimination?.target && formatName(firstUnverifiedElimination.target)}</strong>{' '}
            in this video?
          </span>
        </>
      )}

      {verifyState.currentUpload === undefined && (
        (
          <>
            <div className={'screen-verify-video-wrapper'}>
              <img src={logoLoading} className={'screen-verify-loading-indicator'} />
            </div>
            <span className={'screen-verify-prompt'}>
              Loading...
            </span>
          </>
        )
      )}

      {verifyState.currentUpload === null && (
        (
          <>
            <div className={'screen-verify-video-wrapper'}>
            </div>
            <span className={'screen-verify-prompt'}>
              There are no more videos to verify right now.
            </span>
          </>
        )
      )}

      <button
        type={'button'}
        className={'btn btn-secondary screen-verify-reject'}
        disabled={!verifyState.currentUpload}
        onClick={() => onJudgementClicked(false)}
      >
        No
      </button>
      <button
        type={'button'}
        className={'btn btn-primary screen-verify-approve'}
        disabled={!verifyState.currentUpload}
        onClick={() => onJudgementClicked(true)}
      >
        Yes
      </button>
    </div>
  );
};
