import { Reducer } from 'redux';
import { ThunkAction as BaseThunkAction } from 'redux-thunk';
import type { AppAction, RootState } from '.';

/**
 * This indicates whether a player or a game has been eliminated or not.
 */
export enum Status {
  In = 'in',
  Out = 'out'
}

/**
 * This is the subteam that a player occupies on CUAir in real life. Used to
 * split people into groups along subteam lines.
 */
export enum Affiliation {
  Airframe = 'af',
  Autopilot = 'ao',
  DesOps = 'do',
  Electrical = 'ee',
  Intelligent = 'in',
  Imaging = 'im',
  IntegrationTesting = 'it',
  StructuresPayloads = 'sp',
}

export interface Roles {
  admin: boolean;

  verifier: boolean;

  player: boolean;
}

export interface User {
  netId: string;

  displayName: string;

  firstName: string;

  lastName: string;

  uploads: User;

  team?: Team | number | null;

  status: Status;

  subteam?: Affiliation | null;

  roles: Roles;
}

export interface Team {
  id: number;

  displayName: string;

  target: Team | null;

  users: User[];

  status: Status;
}

export interface Assignment {
  id: number;

  assignee: User;

  target: User;

  date: Date;
}

export interface Elimination {
  id: number;

  target: User;

  eliminator: User;

  verifier: User;

  verified: boolean | null;

  date: Date;
}

export interface Location {
  latitude: number;

  longitude: number;
}

export interface Upload {
  id: number;

  author: User;

  eliminations: Elimination[];

  verify: {
    requested: string | null;

    judged: string | null;
  }

  date: string;

  location: Location;

  video: {
    thirdPartyId?: string | null
  };
}

export interface GameStatusState {
  /**
   * The user who is currently signed in. Undefined means that we have not
   * queried for the current user yet, and null means that no one is logged in.
   */
  self: User | null | undefined;

  /**
   * The users that exist in the game.
   */
  users: Record<string, User> | null;

  /**
   * The teams that exist in the game.
   */
  teams: Record<number, Team> | null;
}

enum ActionType {
  UpdateSelf = 'data:update-self',
  UpdateTeams = 'data:update-team',
  UpdateUsers = 'data:update-user',
}

export type GameStatusAction =
  | UpdateSelfAction
  | UpdateTeamsAction
  | UpdateUsersAction;

type UpdateSelfAction = {
  type: ActionType.UpdateSelf,
  self: User | null,
};

type UpdateTeamsAction = {
  type: ActionType.UpdateTeams,
  teams: Team[],
};

type UpdateUsersAction = {
  type: ActionType.UpdateUsers,
  users: User[],
};

type ThunkAction<A extends GameStatusAction> = BaseThunkAction<void, RootState, undefined, A>;

export function createUpdateStatus(): ThunkAction<GameStatusAction> {
  return async dispatch => {
    {
      const res: Response = await fetch('/auth/me');
      if (res.status === 200) {
        dispatch({
          type: ActionType.UpdateSelf,
          self: await res.json(),
        });
      } else {
        dispatch({
          type: ActionType.UpdateSelf,
          self: null,
        });
        return;
      }
    }

    {
      const res: Response = await fetch('/api/teams');
      if (res.status === 200) {
        dispatch({
          type: ActionType.UpdateTeams,
          teams: await res.json(),
        });
      }
    }

    {
      const res: Response = await fetch('/api/users');
      if (res.status === 200) {
        dispatch({
          type: ActionType.UpdateUsers,
          users: await res.json(),
        });
      }
    }
  };
}

export const gameStatusReducer: Reducer<GameStatusState, AppAction> = (state: GameStatusState | undefined, action) => {
  if (!state) {
    state = {
      self: undefined,
      users: null,
      teams: null,
    };
  }

  switch (action.type) {
  case ActionType.UpdateSelf:
    return {
      ...state,
      self: action.self,
    };

  case ActionType.UpdateTeams:
    return {
      ...state,
      teams: Object.fromEntries(action.teams.map(t => [t.id, t] as const)),
    };

  case ActionType.UpdateUsers:
    return {
      ...state,
      users: Object.fromEntries(action.users.map(t => [t.netId, t] as const)),
    };

  default:
    return state;
  }
};
