import { User } from './store/game-status';

export function formatName(user: User) {
  return (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : user.displayName;
}

/**
 * Removes a random item from `arr` and returns it.
 * @param arr The array to get an item from.
 * @returns A random item from `arr`.
 */
export function pickRandom<T>(arr: T[]): T {
  const idx = Math.floor(Math.random() * arr.length);
  return arr.splice(idx, 1)[0];
}

/**
 * Shuffles `arr` (not in place).
 * @param arr The array to shuffle.
 * @returns A new array containing the elements of `arr` in a random order.
 */
export function shuffle<T>(arr: T[]): T[] {
  const tmp = [...arr];
  const ret = [];
  while (tmp.length > 0) {
    ret.push(pickRandom(tmp));
  }
  return ret;
}
