import { useAppSelector } from '@/store';
import React, {
  ChangeEvent,
  createRef, ReactEventHandler, useCallback, useEffect, useMemo, useReducer, useState,
} from 'react';
import cx from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import logoLoading from '../../asset/160/loading-red.png';
import './info.scss';
import { Status } from '@/store/game-status';
import { formatName } from '@/util';

type UploadFormAction =
  | { type: 'add-eliminated-user', netId: string }
  | { type: 'remove-eliminated-user', netId: string }
  | { type: 'assign-eliminated-user', targetNetId: string, teammateNetId: string };

type UploadFormState =
  {
    eliminations: Record<string, string | null>
  };

export const UploadInfoScreen: React.FC = () => {
  const gameStatus = useAppSelector(state => state.gameStatus);

  const ownTeam = useMemo(() => {
    if (!gameStatus.self || !gameStatus.teams) return null;
    const ownTeamId = gameStatus.self.team as number;
    return gameStatus.teams[ownTeamId];
  }, [gameStatus.self, gameStatus.teams]);

  const targetTeam = useMemo(() => {
    if (!ownTeam || !ownTeam.target) return null;
    return gameStatus.teams![ownTeam.target.id];
  }, [gameStatus.teams, ownTeam]);

  const { state: navState } = useLocation();
  const navigate = useNavigate();
  const { videoBlobUrl } = navState as { videoBlobUrl: string };

  useEffect(() => {
    if (typeof videoBlobUrl !== 'string' || videoBlobUrl.length === 0) {
      console.warn(videoBlobUrl, typeof videoBlobUrl, !!videoBlobUrl, 'no video blob url, sending back to home');
      navigate('/');
    }
  }, [navigate, videoBlobUrl]);

  const videoRef = createRef<HTMLVideoElement>();
  const [videoPlaying, setVideoPlaying] = useState(false);

  const [formState, dispatchForm] = useReducer((state: UploadFormState, action: UploadFormAction): UploadFormState => {
    switch (action.type) {
    case 'add-eliminated-user': {
      return {
        ...state,
        eliminations: {
          ...state.eliminations,
          [action.netId]: null,
        },
      };
    }

    case 'remove-eliminated-user': {
      const tmp = {
        ...state,
        eliminations: {
          ...state.eliminations,
        },
      };

      delete tmp.eliminations[action.netId];

      return tmp;
    }

    case 'assign-eliminated-user': {
      return {
        ...state,
        eliminations: {
          ...state.eliminations,
          [action.targetNetId]: action.teammateNetId,
        },
      };
    }
    }
  }, { eliminations: {} });

  const onVideoError = useCallback<ReactEventHandler<HTMLVideoElement>>((evt) => {
    const nativeEvt = evt.nativeEvent as ErrorEvent;
    console.warn('video error', nativeEvt.error);
  }, []);

  const onVideoClicked = useCallback(() => {
    if (!videoRef.current) return;

    if (videoPlaying) {
      videoRef.current.pause();
    } else {
      void videoRef.current.play();
    }
  }, [videoPlaying, videoRef]);

  const onTargetUserChecked = useCallback((ev: ChangeEvent) => {
    const target = ev.target as HTMLInputElement;

    dispatchForm({
      type: target.checked ? 'add-eliminated-user' : 'remove-eliminated-user',
      netId: target.dataset['netId']!,
    });
  }, [dispatchForm]);

  const onTeammateUserChecked = useCallback((ev: ChangeEvent) => {
    const target = ev.target as HTMLInputElement;

    if (target.checked)
      dispatchForm({
        type: 'assign-eliminated-user',
        targetNetId: target.dataset['targetNetId']!,
        teammateNetId: target.dataset['teammateNetId']!,
      });
  }, [dispatchForm]);

  type UploadStatus = null | { stage: 'progress', progress: number } | { stage: 'success' | 'failed' };
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(null);

  const onFormSubmit = useCallback((ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setUploadStatus({
      stage: 'progress',
      progress: 0,
    });

    (async () => {
      const videoBlobResponse = await fetch(videoBlobUrl);
      const videoBlob = await videoBlobResponse.blob();

      const formData = new FormData();
      formData.append('info', JSON.stringify(formState));
      formData.append('video', videoBlob);

      const xhr = new XMLHttpRequest();

      const success = await new Promise((resolve) => {
        xhr.upload.addEventListener('progress', ev => {
          if (ev.lengthComputable) {
            setUploadStatus({
              stage: 'progress',
              progress: ev.loaded / ev.total,
            });
          }
        });

        xhr.addEventListener('loadend', () => {
          resolve(xhr.readyState === 4 && xhr.status === 200);
        });

        xhr.open('POST', '/api/upload', true);
        xhr.send(formData);
      });

      setUploadStatus({ stage: success ? 'success' : 'failed' });
    })().catch(console.error);
  }, [formState, videoBlobUrl]);

  const activeTeammates = useMemo(() => gameStatus.users && ownTeam?.users
    .map(u => gameStatus.users![u.netId])
    .filter(u => u.status === Status.In),
  [ownTeam?.users, gameStatus.users]);

  const activeTargets = useMemo(() => gameStatus.users && targetTeam?.users
    .map(u => gameStatus.users![u.netId])
    .filter(u => u.status === Status.In),
  [targetTeam?.users, gameStatus.users]);

  if (!videoBlobUrl || !targetTeam || !ownTeam) return null;

  return (
    <div className={'screen-upload'}>
      <div
        className={cx('screen-upload-video-wrapper', { 'screen-upload-video-active': videoPlaying })}
        onClick={onVideoClicked}
      >
        <video
          className={'screen-upload-video'}
          ref={videoRef}
          src={videoBlobUrl}
          onPlay={() => setVideoPlaying(true)}
          onPause={() => setVideoPlaying(false)}
          onError={onVideoError}
          playsInline={true}
        />
      </div>

      <form
        className={'screen-upload-form'}
        onSubmit={onFormSubmit}
        method={'POST'}
        action={'#'}
      >
        <h2>Who is eliminated in this video?</h2>
        <div className={'screen-upload-form-elims'}>
          {
            activeTargets?.map(targetUser => (
              <label key={targetUser.netId} className={'screen-upload-form-elims-item'}>
                <input
                  type={'checkbox'}
                  name={'eliminatedUsers'}
                  checked={targetUser.netId in formState.eliminations}
                  onChange={onTargetUserChecked}
                  data-net-id={targetUser.netId}
                />
                {formatName(gameStatus.users![targetUser.netId])}
              </label>
            ))
          }
        </div>

        {Object.keys(formState.eliminations).map(targetUserNetId => {
          const targetUserDisplayName = gameStatus.users![targetUserNetId].displayName;

          return (
            <>
              <h3>Who eliminated {targetUserDisplayName}?</h3>
              <div className={'screen-upload-form-assign'}>
                {activeTeammates?.map(teammateUser => (
                  <label key={teammateUser.netId} className={'screen-upload-form-assign-item'}>
                    <input
                      type={'radio'}
                      name={`eliminatedUsers:${targetUserNetId}`}
                      checked={formState.eliminations[targetUserNetId] === teammateUser.netId}
                      onChange={onTeammateUserChecked}
                      data-target-net-id={targetUserNetId}
                      data-teammate-net-id={teammateUser.netId}
                      required={true}
                    />
                    {formatName(gameStatus.users![teammateUser.netId])}
                  </label>
                ))}
              </div>
            </>
          );
        })}

        <br />

        <button
          type={'submit'}
          className={'btn btn-primary'}
          disabled={Object.keys(formState.eliminations).length === 0 || uploadStatus !== null}
        >
          Upload Video
        </button>
      </form>

      <div className={cx('screen-upload-overlay', { 'screen-upload-overlay-active': uploadStatus?.stage === 'progress' })}>
        <h3 className={'screen-upload-overlay-title'}>Uploading ⏳</h3>

        <svg viewBox={'0 0 100 100'} className={'screen-upload-overlay-progressbar'}>
          <image href={logoLoading} height={50} width={50} x={25} y={25} />
          <circle
            cx={50} cy={50} r={30}
            fill={'none'}
            strokeDasharray={'188.49 188.49'}
            stroke={'red'}
            strokeWidth={2}
            strokeDashoffset={uploadStatus?.stage === 'progress' ? (1 - uploadStatus.progress) * 188.49 : 0}
          />
        </svg>

        <span className={'screen-upload-overlay-caption'}>Do not close this page.</span>
      </div>

      <div className={cx('screen-upload-overlay', { 'screen-upload-overlay-active': uploadStatus?.stage === 'success' })}>
        <h3 className={'screen-upload-overlay-title'}>Uploaded successfully ✅</h3>
        <p>Your video will be reviewed soon.</p>
        <p>
          <a href={videoBlobUrl} download={'assassin.mp4'}>⏬ Download the video</a>
          <br />
          <br />
          <Link to={'/'}>🔙 Go back</Link>
        </p>
      </div>

      <div className={cx('screen-upload-overlay', { 'screen-upload-overlay-active': uploadStatus?.stage === 'failed' })}>
        <h3 className={'screen-upload-overlay-title'}>Upload failed 🛑</h3>
        <p>
          <p>We couldn&apos;t upload the video. Download it at the link below and send it to Ibiyemi.</p>
          <a href={videoBlobUrl} download={'assassin.mp4'}>⏬ Download the video</a>
        </p>
      </div>
    </div>
  );
};
