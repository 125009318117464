import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './app';
import { User } from './store/game-status';

if (process.env.NODE_ENV !== 'development') {
  const authResponse = await fetch('/auth/me');
  let authData: User | null = null;

  try {
    authData = await authResponse.json();
  } catch (err) {
    console.warn('failed to load auth data');
  }

  Sentry.init({
    dsn: 'https://f1b3ba9812f8432f8e28791d35070871@o190059.ingest.sentry.io/6348356',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    initialScope: {
      user: {
        id: authData?.netId,
      },
    },
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
