import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { useAppSelector } from '../store';
import './assignment.scss';

export const AdminScreen: React.FC = () => {
  const gameStatus = useAppSelector(state => state.gameStatus);

  const navigate = useNavigate();

  useEffect(() => {
    if (!gameStatus.self) return;

    if (!gameStatus.self.roles.admin) {
      navigate('/');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStatus.self?.status, navigate]);

  const onAssignTeamsClick = useCallback(() => {
    fetch('/api/teams', { method: 'put' }).catch(console.error);
  }, []);

  const onShuffleTargetsClick = useCallback(() => {
    fetch('/api/teams/shuffle', { method: 'put' }).catch(console.error);
  }, []);

  return (
    <div className={'screen-admin'}>
      <h1>Admin Dashboard</h1>

      <button type={'button'} className={'btn btn-primary'} onClick={onAssignTeamsClick}>
        Assign Teams
      </button>

      <button type={'button'} className={'btn btn-primary'} onClick={onShuffleTargetsClick}>
        Shuffle Team Targets
      </button>
    </div>
  );
};
