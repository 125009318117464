import { ApiVideoPlayer } from '@/components/apivideo';
import { Upload } from '@/store/game-status';
import { formatName } from '@/util';
import React, {
  createRef, useCallback, useEffect, useState,
} from 'react';
import './timeline.scss';

const reformatDate = (date: string) => new Date(date).toLocaleString(undefined, {
  dateStyle: 'short',
  timeStyle: 'short',
});

const TimelineVideo: React.FC<{ uploadId: number }> = ({ uploadId }) => {
  const videoRef = createRef<HTMLVideoElement>();

  const onWindowScroll = useCallback(() => {
    if (!videoRef.current) return;
    const rect = videoRef.current.getBoundingClientRect();
    if (rect.bottom < window.innerHeight / 2 || rect.top > window.innerHeight / 2) {
      videoRef.current.pause();
    }
  }, [videoRef]);

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);

    return () => window.removeEventListener('scroll', onWindowScroll);
  }, [onWindowScroll]);

  const onVideoClick = useCallback(() => {
    if (!videoRef.current) return;

    if (videoRef.current.paused) {
      videoRef.current.play().catch(console.error);
    } else {
      videoRef.current.pause();

    }
  }, [videoRef]);

  return (
    <video
      className={'screen-timeline-post-video'}
      src={`/api/upload/${uploadId}/stream`}
      playsInline={true}
      loop={true}
      ref={videoRef}
      onClick={onVideoClick}
    />
  );
};

export const TimelineScreen: React.FC = () => {
  const [retrievedUploads, setRetrievedUploads] = useState<Upload[]>([]);

  useEffect(() => {
    fetch('/api/uploads')
      .then(async res => {
        setRetrievedUploads(await res.json() as Upload[]);
      })
      .catch(console.error);
  }, []);

  return (
    <div className={'screen screen-timeline'}>
      <div className={'screen-timeline-posts'}>
        <h1>Eliminations</h1>
        {retrievedUploads
          .filter(upload => upload.eliminations.some(e => e.verified))
          .map(upload => (
            <div className={'screen-timeline-post'} key={upload.id}>
              <div className={'screen-timeline-post-header'}>
                <span className={'screen-timeline-player-name screen-timeline-post-author'}>
                  {formatName(upload.author)}
                </span>
                <span className={'screen-timeline-post-date'}>
                  {reformatDate(upload.date)}
                </span>
              </div>

              {
                upload.video.thirdPartyId
                  ? (
                    <ApiVideoPlayer
                      className={'screen-timeline-post-video-external'}
                      videoId={upload.video.thirdPartyId}
                      hideTitle={true}
                    />
                  )
                  : (
                    <TimelineVideo uploadId={upload.id} />
                  )
              }

              <div className={'screen-timeline-post-footer'}>
                <ul className={'screen-timeline-post-elims'}>
                  {upload.eliminations.map(elim => (
                    <li className={'screen-timeline-post-elims-item'} key={elim.id}>
                      <span className={'screen-timeline-player-name'}>{formatName(elim.eliminator)}</span> eliminated <span className={'screen-timeline-player-name'}>{formatName(elim.target)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
