import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { GameStatusAction, gameStatusReducer } from './game-status';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type AppAction =
  | GameStatusAction;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const reducer = combineReducers({
  gameStatus: gameStatusReducer,
});

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<any, AppAction>))
);
